
import styled from 'styled-components';
import logo from './assets/logo.png';
// import arrow from './assets/arrow.png';
import bg from './assets/bg.png';
import { useWindowSize } from 'react-use';

const Container = styled.div`
width:100vw ;
overflow:hidden;


font-family: 'Inter';
`
const Inner = styled.div`
width:1280px;
margin:0 auto ;
min-height:100vh;
background-image:url(${bg});
background-size:contain;
position:relative ;

@media screen and (max-width: 1280px){
  width:100%;
}

 `
const Header = styled.div`
position:fixed ;
width:100vw;
height:100px ;
padding:0 5vw ;
box-sizing:border-box ;

display:flex ;
align-items:center ;
justify-content:space-between ;

z-index:999;
`

// const Nav = styled.div`
// display:flex ;
// font-size: 19px;
// align-items:center ;

// &>div:not(:last-child){
//     margin-right:39px ;
// }

// @media screen and (max-width: 1280px){
//         font-size:15px ;
//     }

// `

const Text = styled.div`
    width:fit-content ;
    font-size:90px ;
    font-family: 'Inter-Medium';
    font-weight:600 ;
    position:relative ;
    margin:0 auto ;


    &>span{
        position:absolute ;
        right:0 ;
        font-size:22px;
    }

    @media screen and (max-width: 1280px){
     font-size:60px ;

     &>span{

        font-size:18px;
      }
    }

    @media screen and (max-width: 750px){
     font-size:30px ;

     &>span{

        font-size:14px;
      }
    }
 `

const Link = styled.button`
  width: 171px;
  height: 40px;
  align-items: center;
  background: #000000;
  border-radius: 30px;
  background-color:#000;
  border: none;
  cursor: pointer;
  font-size:21px ;
  color:#fff ;

  @media screen and (max-width: 720px){
    width: 130px;
  }

  a{
    text-decoration:none ;
    display:inline-block;
    width:100%;
    height:100%;
    color:#ffffff;
    font-size:18px;
    line-height:40px;
    font-family: 'fontspring-extralight';
    font-weight:normal;
     display: flex;
     justify-content:center;
     align-items: center;

    @media screen and (max-width: 720px){
      font-size: 16px;
    }
  }
`

const Button = styled.button`
width:266px ;
height:50px;
background-color:gray ;
color:#fff ;
border:none ;
font-size:22px ;
border-radius:30px ;

@media screen and (max-width: 1280px){
        width:200px ;
        height:45px ;
        font-size:18px ;
    }
@media screen and (max-width: 750px){

        width:150px ;
        height:37px ;
        font-size:14px ;
}
 `

const Title = styled.div`
width:100% ;
text-align:center ;
white-space:pre-wrap ;
 `
const Logo = styled.img`
width:147px;
height:auto ;

@media screen and (max-width:1280px){
    width:115px ;

}
 `
// const Arrow = styled.img`
//     width:12px ;
//     height:auto ;
//     margin-left:4px ;
// `
const Right = styled.div`
    display:flex ;

 `

// const Feature = styled.div`
// display:flex ;
// align-items:center ;
//  `
// const NavItem = styled.div`

// `

const Main = styled.div`
width:100%;
padding:0 10vw ;
position:absolute ;
top:calc(50% - 20px);
left:50%;
transform:translate(-50%,-50%) ;
text-align:center ;
`

const Desc = styled.div`
font-size:18px ;
font-family: 'Inter';
white-space:pre-line ;
margin-top:50px ;

@media screen and (max-width: 1280px){
        font-size:14px ;
}
@media screen and (max-width: 750px){
        padding:0 20px;
        font-size:12px ;
}
 `

const Btn = styled.div`
 display:flex ;
 margin-top:50px ;
 justify-content:center ;

 &>a:first-child{
    margin-right:30px ;
 }

 @media screen and (max-width: 750px){
  width:100% ;
    a:first-child{
      margin-right:15px ;
    }
 }
`

export function FirstPage() {
  const { width } = useWindowSize();

  return (
    <Container>
      <Header>
        <Logo src={logo} />
        <Right>
          {/* <Nav>
                        <Feature>
                            Features
                            <Arrow src={arrow} />
                        </Feature>
                        <NavItem>Docs</NavItem>
                        <NavItem>Templates</NavItem>
                        <NavItem>Integrations</NavItem>
                    </Nav> */}
          <Link><a href="https://app.did.eco/">Launch App</a></Link>
        </Right>
      </Header>

      <Inner>

        <Main>
          <Title>
            <Text>
              Link. Find. Grow.
              <br />
              <span>w/ENS</span>
            </Text>
          </Title>
          <Desc>
            {
              width > 450 ? `The must-have platform for NFT collectors, DAO believers, and all
            communities to amplify social awareness in Web2 and Web3.`:
                `The must-have platform for NFT collectors,
            DAO believers,
            and all communities to amplify social
            awareness in Web2 and Web3.`
            }
          </Desc>
          <Btn>
            <a href="https://app.did.eco/user">
              <Button>Collect ldentity</Button>
            </a>
            <a href="https://app.did.eco/dao_id">
              <Button>Launch Namesapce</Button>
            </a>
          </Btn>
        </Main>
      </Inner>
    </Container>
  );
}
